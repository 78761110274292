import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../components/mdx/page";
import { Breadcrumbs } from "../../../components/widgets/breadcrumbs";
import { Aside } from "../../../components/widgets/aside";
import { Border } from "../../../components/widgets/container";
import { ButtonPrimary, ButtonSecondary } from "../../../components/widgets/button";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{`How We Backtest Our Portfolios`}</h1>
    <Breadcrumbs text="Articles" to="/articles/" mdxType="Breadcrumbs" />
    <Aside mdxType="Aside">
      <p><strong parentName="p">{`See Also`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/articles/live-trading-vs-backtests/"
          }}>{`Live Trading vs. Backtests`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/help/tactical-asset-allocation/"
          }}>{`Tactical Asset Allocation`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/help/charts-metrics/"
          }}>{`Our Charts and Metrics`}</a></li>
      </ul>
      <p><strong parentName="p">{`TuringTrader Open-Source`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.turingtrader.org"
          }}>{`Homepage`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://github.com/fbertram/TuringTrader"
          }}>{`Repository`}</a></li>
      </ul>
    </Aside>
    <p><a parentName="p" {...{
        "href": "/help/tactical-asset-allocation/"
      }}><em parentName="a">{`Tactical Asset Allocation`}</em></a>{` dynamically tilts asset allocation to take advantage of fluctuating market conditions. This approach creates a dilemma: we need to cover many years to test our strategies with many different economic scenarios. But at the same time, markets are constantly evolving, and we need to stay current with our methods and strategies.`}</p>
    <p>{`Computer simulations, so-called `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Backtesting"
      }}>{`backtests`}</a>{`, solve this issue by allowing us to run many sophisticated tests within a short period. Therefore, it is fair to say that backtesting is at the core of everything we do at `}<em parentName="p">{`TuringTrader.com`}</em>{`. We aim to provide the most accurate backtests for our portfolios so that you can feel comfortable investing with them. In this post, we explain the assumptions we make and the techniques we use.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0f924b4ab17672bf936d1187934457b1/9c5b3/alex-kondratiev-H9t723yPjYI-unsplash.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIEA//EABQBAQAAAAAAAAAAAAAAAAAAAAL/2gAMAwEAAhADEAAAAbLEwDQsGP/EABkQAAMBAQEAAAAAAAAAAAAAAAECEQMABP/aAAgBAQABBQLHR2ZgYU1sCZ+dqt7/xAAXEQEAAwAAAAAAAAAAAAAAAAABAhAS/9oACAEDAQE/AQWOq//EABYRAAMAAAAAAAAAAAAAAAAAAAEQUf/aAAgBAgEBPwERf//EABsQAAIBBQAAAAAAAAAAAAAAAAABEBEhUWHh/9oACAEBAAY/Asljo6D04//EABsQAAMAAgMAAAAAAAAAAAAAAAABESFBMWFx/9oACAEBAAE/IYR1N9DEbD3KeBwkciweR//aAAwDAQACAAMAAAAQWB//xAAXEQADAQAAAAAAAAAAAAAAAAAAAREx/9oACAEDAQE/EGjMKz//xAAXEQEBAQEAAAAAAAAAAAAAAAABABFB/9oACAECAQE/EEiPtpf/xAAbEAEAAwEAAwAAAAAAAAAAAAABABEhMUFRYf/aAAgBAQABPxA0PzzrjGVF4baoluCAAKghHUU+d3OzW7g37I5xP//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "TuringTrader backtesting assumptions",
            "title": "TuringTrader backtesting assumptions",
            "src": "/static/0f924b4ab17672bf936d1187934457b1/e5166/alex-kondratiev-H9t723yPjYI-unsplash.jpg",
            "srcSet": ["/static/0f924b4ab17672bf936d1187934457b1/f93b5/alex-kondratiev-H9t723yPjYI-unsplash.jpg 300w", "/static/0f924b4ab17672bf936d1187934457b1/b4294/alex-kondratiev-H9t723yPjYI-unsplash.jpg 600w", "/static/0f924b4ab17672bf936d1187934457b1/e5166/alex-kondratiev-H9t723yPjYI-unsplash.jpg 1200w", "/static/0f924b4ab17672bf936d1187934457b1/d9c39/alex-kondratiev-H9t723yPjYI-unsplash.jpg 1800w", "/static/0f924b4ab17672bf936d1187934457b1/df51d/alex-kondratiev-H9t723yPjYI-unsplash.jpg 2400w", "/static/0f924b4ab17672bf936d1187934457b1/9c5b3/alex-kondratiev-H9t723yPjYI-unsplash.jpg 5456w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Backtesting Engine`}</h2>
    <p>{`It comes as no surprise that we use the backtesting engine from `}<a parentName="p" {...{
        "href": "https://www.turingtrader.org"
      }}><em parentName="a">{`TuringTrader.org`}</em></a>{`. Unlike many commercial backtesters, we designed the TuringTrader engine with portfolio-level simulations in mind, making it an ideal framework for our portfolios on `}<em parentName="p">{`TuringTrader.com`}</em>{`.`}</p>
    <p><em parentName="p">{`TuringTrader.org`}</em>{` is an open-source project. You can download and use it as you see fit and free of charge. Even better, you can download the source code, analyze how it works, and adapt it to your application. However, the license terms require any derivative work also to be open-source. `}<em parentName="p">{`TuringTrader.org`}</em>{` uses the `}<a parentName="p" {...{
        "href": "https://www.gnu.org/licenses/agpl-3.0.en.html"
      }}><em parentName="a">{`GNU Affero General Public License`}</em></a>{`, which includes running derivative work on a server in its definition of software distribution.`}</p>
    <p><em parentName="p">{`TuringTrader.com`}</em>{` uses the same backtesting engine. There are no hidden features we use that aren't also available to you. However, `}<em parentName="p">{`TuringTrader.com`}</em>{` uses custom report templates to implement some of the site's features.`}</p>
    <h2>{`Data Feeds and Proxies`}</h2>
    <p>{`Without accurate data, any backtest is bound to fail. We receive most of our data from `}<a parentName="p" {...{
        "href": "https://norgatedata.com/"
      }}>{`Norgate`}</a>{`, an Australian provider of high-quality daily data. We collect these data after the markets closed and are dependent on `}<a parentName="p" {...{
        "href": "https://www.premiumdata.net/products/premiumdata/usupdatetimes.php"
      }}>{`Norgate's update schedule`}</a>{`. To provide you with updated portfolio allocations as early as possible, we can't wait for all final data. Instead, we use interim releases for the initial site updates, and run two additional updates later each night to include later updates. Here is the schedule (times in Eastern/ New York time zone):`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "783px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/34e39bb464383e9aadd0b7b3ed788ef6/e51a6/update-schedule.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACeklEQVQ4y12RiW7bRhBA9f9/VBRBCjeOa8dRFF28dnnfp2RSInXxFVy5DZwFBou93ryZnWkywvQyqvaMCEq+LQVpfaDuLuh2ghs3OHHD95VkKyOcsCQuO+KyVXOx6xnHkWlM80zYPlOcLlBUb2w0CzdIGC4jfpRR1G/k1Z711lRn0glI8oooLQjjnGrX8U77DxggnVABsnJ6aBEmJf15xAsz0mKnYq1ZbHWJIVxM4SLdiLTcsT+cPhpa0kf8Btx3A6cruEFKWjRqf2tINMNGM222ukC6oUpcNi3vuN+BvJuI/4F+XFDvD0RpzUYXCjoZOn6MZfuqHYfhyu02/ip5Up+yKWC5RzcdlfV4vuFFubJL8obV1lLQ6f6u7VWiqX9T8rvhfSjDqdHD+VfJthvRn264fkIQ56QTcGOgGRLXjymqPWX9Rtm8KfhtGBjblnEYmJmPL8iHJwbbJ1ts2Pz5F/aXZ47SI3hZUG8MkvmK1R+fEA9fiV4XdJaj7ve2z0G4XEzBVdO5WoKZ5SeYXsxxhGx/ZCM8iranH8HLapKmJd0d2AgXzQ5YaIKw3NED3fUeH0qW8xXG0yvHOCMzbLaPz3RBwiktCZY6tfRIdcn64QlnvsT9saYwHY5RxiFK6cKUi+cru6vnM5OLNdb3n/R5RS48Vo8vlLbPkNcEG5PGj0lNm9XjM2K+xFtqal05AX1W0cU5lyjm6npc44SZ+PYD8fRKHySkawP9739IVhoHL8Kfr6gMSbrWWX/+gvbwleWnB7U+uCGt7dO54d3Qdu6Gwg2xpl+9Qla3LLeCcn/kBDhRRlQ0RMWe9VS2YSP9mChv1H6+6+jO44ce/gv4McMrMZU3fwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "update schedule",
            "title": "update schedule",
            "src": "/static/34e39bb464383e9aadd0b7b3ed788ef6/e51a6/update-schedule.png",
            "srcSet": ["/static/34e39bb464383e9aadd0b7b3ed788ef6/5a46d/update-schedule.png 300w", "/static/34e39bb464383e9aadd0b7b3ed788ef6/0a47e/update-schedule.png 600w", "/static/34e39bb464383e9aadd0b7b3ed788ef6/e51a6/update-schedule.png 783w"],
            "sizes": "(max-width: 783px) 100vw, 783px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`We source a few of the more nichey economic data from `}<a parentName="p" {...{
        "href": "https://fred.stlouisfed.org/"
      }}>{`FRED`}</a>{`. Economic data often do not align with the market days of U.S. stock exchanges. Also, the sampling intervals are often different, e.g., weekly or monthly. We resample these, without interpolation, to align with U.S. market days. Further, some economic data have a publication lag. We aim to account for this lag by delaying the data accordingly in our simulations.`}</p>
    <p>{`All of our equity quotes are fully `}<a parentName="p" {...{
        "href": "https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3354728"
      }}>{`adjusted for splits and dividends`}</a>{`. This adjustment makes sure that we can calculate indicators without disruption. Even more importantly, using adjusted quotes implies that any interest or dividend received is re-invested in the portfolios.`}</p>
    <p>{`We aim to provide simulations reaching back to January 2007 for all of our portfolios. We chose this time balancing practicality with the desire to show a full economic cycle. Simulating this far back bears some issues, though.`}</p>
    <p>{`For strategies trading individual stocks, we need to make sure that the traded universe is free of `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Survivorship_bias"
      }}>{`survivorship bias`}</a>{`. Our stock-trading strategies use major indices as their trading universe. For these, we have access to historical index constituents, including de-listed, re-named, or merged instruments.`}</p>
    <p>{`For strategies trading ETFs, we face the problem that many ETFs have inception dates later than 2007. To test that far back, we extend the history of ETFs using proxy instruments as required. There are many different kinds of proxies available, including similar ETFs, mutual funds, or indices. If none of these are available, we use mathematical models, e.g., to model total returns of Treasuries from the yields.`}</p>
    <h2>{`Portfolio Implementations`}</h2>
    <p>{`Most of our `}<strong parentName="p">{`Basic`}</strong>{` portfolios come from books and publications. Unless otherwise noted, we aim to implement these portfolios verbatim and without making any adjustments. To honor the spirit of the authors who shared their ideas with the world, we provide our implementations of these strategies under the same open-source license as the backtesting engine. Curious investors can review the source code in the `}<a parentName="p" {...{
        "href": "https://github.com/fbertram/TuringTrader"
      }}>{`open-source repository`}</a>{`.`}</p>
    <p>{`Our `}<strong parentName="p">{`Premium`}</strong>{` portfolios are proprietary. We provide a general description of the mechanics, and often also a background article with additional insights. Please understand that we cannot share their source code, though. After all, these `}<strong parentName="p">{`Premium`}</strong>{` portfolios are what makes `}<em parentName="p">{`TuringTrader.com`}</em>{` unique.`}</p>
    <h2>{`Rebalancing and Order Placement`}</h2>
    <p>{`The portfolios on `}<em parentName="p">{`TuringTrader.com`}</em>{` require daily, weekly or monthly rebalancing. Unless otherwise noted, we assume that portfolios are rebalanced using market orders submitted while the markets are closed. These will be executed at the next open and within regular market hours.`}</p>
    <p>{`Portfolios rebalancing weekly typically do so over the weekend, with only a few exceptions. Our monthly rebalanced portfolios do so after the last trading day of the month.`}</p>
    <p>{`Our backtesting engine does not support fractional shares. Instead, all of our simulations use sufficiently large account sizes to minimize the rounding errors caused by rounding the number of shares to integer values.`}</p>
    <h2>{`Fees and Slippage`}</h2>
    <p>{`For every order placed, we deduct a fee of $0.015 per share. This amount matches the `}<a parentName="p" {...{
        "href": "https://www.interactivebrokers.com/en/index.php?f=1590&p=stocks1"
      }}>{`fees and commissions paid at major brokerages`}</a>{`. Because we are using split-adjusted prices, the number of shares traded in the simulation might differ from the actual amount traded historically. We are currently unable to account for this issue. However, except for assets performing reverse splits, this usually results in the simulation's assumptions being more pessimistic than reality.`}</p>
    <p>{`Many brokers use a tiered fee structure and set a minimum fee, e.g., of $1.00 per order. We do not attempt to model this. Instead, we assume that all orders are large enough to exceed that limit. Consequently, the fees adopted by our backtests model the reality of large accounts better than that of small accounts. This model is in line with the assumptions we made above regarding the rounding of shares to integer values.`}</p>
    <p>{`Orders are currently filled at the open price, not accounting for slippage. We will be revisiting this topic in the future to see if we can implement a better fill model. The portfolios on `}<em parentName="p">{`TuringTrader.com`}</em>{` use the most liquid assets available, which should keep slippage low.`}</p>
    <p>{`We hope this article provided you some insight into the methods and assumptions we use in our backtesting. We recommend interested investors to read how live trading compares to backtested results.`}</p>
    <ButtonPrimary text="live trading vs backtest" to="/articles/live-trading-vs-backtests/" mdxType="ButtonPrimary" />
    <br /><br /><br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      